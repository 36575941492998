<template>
  <div class="bg-white border w-100 mx-auto mt-5">
    <b-card-header  v-if="signData" class="h4 font-weight-normal text-center bg-white"
      >Информация об электронном документе</b-card-header>
    <div class="m-3 mb-0 font-styles" v-if="signData" >
      <div class="text-center bg-success text-white text-uppercase p-2">
        Подписант - {{ signData.fio }}
      </div>
      <b-row class="w-100 mx-auto mt-3 p-0  border-bottom">
        <b-col align-self="start" class="p-2 text-left ">Результат проверки сертификата</b-col>
        <b-col v-if="signData.cert_check == 'success'" align-self="start" class="p-2  my-auto mx-auto text-white bg-success bg-opacity-50  border-left"> Успешно
        </b-col>
        <b-col v-else align-self="start" class="p-2 text-left text-white bg-danger bg-opacity-50  border-left"> Неудачно
        </b-col>
      </b-row>
      <b-row class="w-100 mx-auto p-0 m-0  border-bottom bg-light">
        <b-col align-self="start" class="p-2 text-left ">Результат проверки подписи</b-col>
        <b-col v-if="signData.sign_check == 'success'" align-self="start" class="p-2 text-left my-auto mx-auto text-white bg-success bg-opacity-50 border-left"> Успешно
        </b-col>
        <b-col v-else align-self="start" class="p-2 text-white bg-danger bg-opacity-50  border-left"> Неудачно
        </b-col>
      </b-row>
      <b-row class="w-100 mx-auto p-0 m-0  border-bottom ">
        <b-col align-self="start" class="p-2 text-left ">ИИН</b-col>
        <b-col align-self="start" class="p-2 text-left  border-left"
          >{{ signData.iin }}
        </b-col>
      </b-row>
      <b-row class="w-100 mx-auto p-0 m-0  border-bottom bg-light">
        <b-col align-self="start" class="p-2 text-left ">ФИО</b-col>
        <b-col align-self="start" class="p-2 text-left  border-left"
          >{{ signData.fio }}
        </b-col>
      </b-row>
      <b-row class="w-100 mx-auto p-0 m-0  border-bottom">
        <b-col align-self="start" class="p-2 text-left ">Срок действия сертификата</b-col>
        <b-col align-self="start" class="p-2 text-left  border-left"
          >{{ signData.cert_from }} по {{ signData.cert_to }}
        </b-col>
      </b-row>
      <b-row class="w-100 mx-auto p-0 m-0  border-bottom bg-light">
        <b-col align-self="start" class="p-2 text-left ">Дата подписания</b-col>
        <b-col align-self="start" class="p-2 text-left  border-left"
          > {{ new Date( signData.sign_date).toLocaleString("kz-KZ", {timeZone: "Asia/Qyzylorda"})  ??  '' }}
        </b-col>
      </b-row>
      <form @submit.prevent="download" class="text-right">
        <button type="submit" class="btn btn-info mt-3 mb-3">Скачать документ</button>
      </form>
      <div class="text-right">
        <span class="font-weight-normal">*Для проверки подлинности документа перейдите на сайт &nbsp; <a class="text-secondary" href="https://ezsigner.kz" target="blank">ezsigner.kz</a></span>
      </div>
    </div>
    <b-card-header  v-if="!signData" class="h6 font-weight-normal text-center bg-white"
      >Не удалось проверить документ</b-card-header>
  </div>
</template>

<script>
export default {
  data() {
    return {
      signData : undefined
    };
  },
  methods: {
    download(){     
        if(this.signData.path){
          this.$api
          .get(`web/ttn/download/web?path=${this.signData.path}`, {responseType: "blob"})
          .then((response) => {
              var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 
              'application/pdf'}));
              var fileLink = document.createElement('a');
              fileLink.href = fileURL;
              fileLink.setAttribute('download', `${this.$route.query.order_id}.pdf`);
              document.body.appendChild(fileLink);
              fileLink.click();
          }); 
        } 
    }
  },
  mounted() {
    this.$emit("spinner");
  },
  async created(){
      await this.$api.get(`/v2/ttn/get/web?order_id=${this.$route.query.order_id}&point_id=${this.$route.query.point_id}`)
      .then( (resp) => {
        this.signData = resp.data.fio ? resp.data : undefined
      })
     
  }
};
</script>

<style scoped>
  .font-styles {
    color: var(--main-color);
    font-family: "Product Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
  }
</style>
